import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/Page'

export const query = graphql`
  query HomePageQuery($uid: String) {
    prismic {
      allHomepages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            has_contact_block
            about_image
            about_imageSharp {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            about_image_additional
            about_image_additionalSharp {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            about_link_text
            about_text
            about_title
            description
            has_contact_block
            hero_description
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            hero_title
            news_link_text
            news_text
            news_title
            page_preview_image
            page_preview_imageSharp {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            portfolio_intro
            portfolio_link_text
            portfolio_title
          }
        }
      }
    }
  }
`

const PageContainer = props => (
  <Page
    {...props}
    prismicObjectPath="data.prismic.allHomepages.edges[0].node"
  />
)

export default PageContainer
